.project-heading {
  font-size: 1.8rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #f2efe6;
  border-bottom: 3px groove;
  // border-radius: 10px;
  border-color: #1d9e65;
  text-align: center;
  margin: 6% auto 0%;
  width: 25%;
  font-weight: 600;
}

.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

// ****** PROJECT ITEM STYLES ******* //

.container {
  // margin: 0 auto 2% auto;
  display: grid;

  grid-column-gap: 1%;
  grid-row-gap: 1%;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  font-family: "Comic Neue", cursive;
  padding: 1%;
  width: 100%;
  margin-top: 5%;
}

.project1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: #ff8364;
  padding: auto 0;
  // height: 25rem;
}

.project2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: #5daf72;
  padding: 1%;
}

.project3 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: rgb(103, 77, 189);
  padding: 1%;
}

.project4 {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: #FFC785;
  padding: 1%;
}

.ih-item.square {
  position: relative;
  border-radius: 8px;
  box-shadow: 5px 7px 5px rgba(0, 0, 0, 0.466);
}

.ih-item.square .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  backface-visibility: hidden;
}

.ih-item.square.effect15 a:hover .info {
  visibility: visible;
  opacity: 1;
}

.ih-item.square.effect15.left_to_right .img {
  transform: rotateY(0);

  transform-origin: 100% 50%;
}

.project-image {
  width: 100%;
  // height: 100%;
  // margin: 5%;
}

.ih-item.square.effect15.left_to_right .info {
  transform: rotateY(90deg);
  transform-origin: 0% 50%;
}

.ih-item.square.effect15.left_to_right a:hover .img {
  transform: rotateY(-90deg);
}

.ih-item.square.effect15.left_to_right a:hover .info {
  transform: rotateY(0);
}

.ih-item.square.effect15 {
  overflow: hidden;

  perspective: 900px;
}

.ih-item.square.effect15.colored .info {
  background: goldenrod;

  ;
}

.ih-item.square.effect15 .img {
  opacity: 1;

  transition: all 0.4s ease-in-out;
}

.ih-item.square.effect15 .info {
  background: #333333;
  opacity: 0;

  transition: all 0.35s ease-in-out 0.3s;
}

.ih-item.square.effect15 .info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  background: #111111;
  margin: 30px 0 0 0;
  font-weight: 800;
}

.ih-item.square.effect15 .info p {
  font-style: italic;
  font-size: 1rem;
  position: relative;
  color: rgb(255, 255, 255);
  padding: 20px 20px 20px;
  text-align: left;
  letter-spacing: 0.3;
}

.ih-item.square.effect15 a:hover .img {
  opacity: 0;
  visibility: hidden;
}

.ih-item.square.effect15 a:hover .info {
  visibility: visible;
  opacity: 1;
}

// ***** Media Queries ***** ////

@media only screen and (max-width: 600px) {
  .container {
    // margin: 0 auto 2% auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // flex-wrap: wrap;
  }

  .projects {
    padding-bottom: 10%;
  }

  .ih-item.square {
    margin: 3% 10%;
  }
}