.sidebar {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 5%;
  display: flex;
  background-color: #00000098;
  justify-content: flex-end;
  z-index: 1000;
}

.line{
  color: #ffd800;
  padding: 0 1%;
}

.page-links {
  margin-right: 1%;
  margin-top: 1%;
  width: 70%;
  text-align: right;
}

.sidebar-menu {
  padding: 1%;
  color: #fbeecf;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Comic Neue", cursive;
  font-weight: 700;
  font-size: 1rem;
  // width: 70%;
}

.sidebar-menu:hover {
  color: #ffd800;
  text-decoration: none;
}

// **************************//

.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51.5%;
  bottom: 0;
  background: #ffd800;
  height: 2px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .page-links {
    margin-right: 2%;
    margin-top: 2%;
    width: 100%;
    text-align: center;
 
  }


  .sidebar-menu {
    padding: 1%;
    font-size: 0.85rem;
    padding: 0 1.5%;
  }
}