.resume-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.experience-heading,
.education-heading {
  font-size: 1.5rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #fbeecf;
  border-bottom: 3px groove;
  border-radius: 10px;
  border-color: #ffd800;
  text-align: center;
  font-weight: 600;
  margin: 8% auto;
  width: 50%;
  padding-bottom: 5px;
}

// ***** Experience & Education items ****** //

.line-left {
  width: 3px;
  height: 100%;
  background-color: #ffd800;
  left: -10px;
  top: 0;
  position: absolute;
}

ul.experience-content li {
  position: relative;
  margin-left: 10px;
  padding: 3% 4%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

ul.experience-content {
  margin: 10% 0%;
  list-style: none;
  position: relative;
  padding: 0 0 0 50px;
  width: 100%;
  font-size: 1.1rem;
  font-family: "Comic Neue", cursive;
}

span.to {
  position: absolute;
  top: -22px;
  left: -11px;
  font-weight: 800;
  color: #ffd800;
}

span.from {
  position: absolute;
  bottom: -22px;
  left: -10px;
  font-weight: 800;
  color: #ffd800;
}

.content {
  margin-left: 10px;
}

.job-title {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.job-link{
  text-decoration: none;
  color: #ffd800;
}

.company {
  font-weight: 800;
  font-size: 1rem;
}

// ******* skills ****** //

.skills {
  padding-bottom: 3%;
}

.skills-heading {
  font-size: 1.5rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #f2efe6;
  border-bottom: 3px groove;
  border-radius: 10px;
  border-color: #ffd800;
  text-align: center;
  font-weight: 600;
  margin: 0% auto;
  width: 30%;
  font-weight: 800;
  padding-bottom: 5px;
}

.skills-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.skills-title {
  font-size: 1.2rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #f2efe6;
  font-weight: 400;
  margin-top: 3%;
}

.skills-progress {
  margin: 0% 3% 5%;
}

.progress-bar {
  font-size: 1.2rem;
}

.bg-dark {
  background-color: #4b2303 !important;
}

@media only screen and (max-width: 600px) {
  .resume-content {
    display: flex;
    flex-direction: column;
  }

  .experience-heading,
  .education-heading {
    font-size: 1.1rem;

    margin: 0% auto;
  }

  .line-left {
    width: 2px;
  }


  ul.experience-content {
    margin: 10% 0%;
    list-style: none;
    position: relative;
    // padding: 0 0 0 50px;
    width: 100%;
    font-size: 1rem;
  }

  ul.experience-content li {
    margin: 20% 0%;
  }

  .job-title {
    font-size: 1.1rem;
  }

  .company {
    font-size: 0.9rem;
  }

  .skills-section {
    margin-bottom: 10%;
  }

  .skills-title {
    font-size: 1rem;
    margin-top: 10%;
  }

  .skills-heading {
    font-size: 1.1rem;

  }
}