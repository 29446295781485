.main-page {
  min-height: 100vh;
  background-color: #000000;
  background-image: url("../images/background.png");

  background-size: cover;
  position: relative;
  left: 0;
  right: 0;
}

.pic {
  position: absolute;
  left: 2%;
  bottom: 0;
  width: 37%;
}

.profile-picture {
  position: absolute;
  overflow: hidden;
  top: 0;
  height: 100vh;
  width: 100%;
}

.home-name {
  position: absolute;
  top: 40%;
  right: 20%;
  font-family: "Chelsea Market", cursive;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  font-size: 5rem;
  color: #ffd800;
}



.tag {
  position: absolute;
  top: 52.5%;
  left: 54.5%;
  font-family: "Chelsea Market", cursive;

  font-weight: 600;
  font-size: 1.8rem;
  color: #fdffba;
  display: flex;
  align-items: center;
}

.quality {
  color: #ffd800;
  text-transform: uppercase;
  border-bottom: 4px solid #fdffba;
  font-family: "Chelsea Market", cursive;
  font-weight: 700;
  font-size: 2rem;
  // font-style: italic;
}

.cursor {
  color: #ffd800;
}

.sidebar .sidebar-menu .fas {
  margin-right: 5%;
}

.fab {
  font-size: 2.5rem;
  color: white;
  padding-bottom: 5%;
}

.fab:hover {
  color: #ffd800;
}

.social-media {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 3%;
  bottom: 5%;
}

.social-media-link {
  padding: 0.7rem;
}

.copyright {
  position: absolute;
  bottom: 0.5%;
  left: 45%;
  font-size: 0.8rem;
  color: #ffd800;
}


.fixture-container {
  position: absolute;
  top: 10%;
  left: 10%;
  margin: auto 0;
  width: 30%;
}

.fixture-heading {
  font-family: "Chelsea Market",
    cursive;
  text-decoration: underline;
  color: #479c7c;
  text-align: center;

}


.fixture-item {
  display: flex;
  flex-direction: column;
  padding: 2%;

}

.single-fixture {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 1%;
  border-bottom: 0.5px solid salmon;
  border-radius: 10px;
  background-color: #FFFDDE;
  padding: 1%;
  min-height: 7rem;
}

.fixture-vs {
  font-family: "Chelsea Market",
    cursive;
  color: #84e77b;
}

.fixture-date {
  font-family: "Chelsea Market",
    cursive;
  font-size: 0.8rem;
  color: #479c7c;
}

.fixture-status {
  font-family: "Chelsea Market",
    cursive;
  font-size: 0.8rem;
  color: #d4b735;
}

.details-column {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
}

.team-logo {
  height: 75%;
  width: 30%;
  margin-right: 3.5%;
}

.league-logo {
  height: 100%;
  width: 17.5%;
}

.team-name {
  font-family: "Chelsea Market",
    cursive;
  font-size: 1rem;
  color: #694E4E;

}

//  **** Media Queries *** //

@media only screen and (max-width: 600px) {
  .home-name {
    position: absolute;
    top: 17.5%;
    right: 5%;
    font-size: 2.5rem;
  }


  .pic {
    position: absolute;
    left: 2%;
    bottom: 0;
    width: 95%;
    // height: 100%;
  }

  .tag {
    position: absolute;
    top: 28%;
    left: 11%;
    font-size: 1rem;
  }

  .quality {
    font-size: 1.2rem;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: 27.5%;
    font-size: 0.6rem;
  }
}