.wrapper {
  margin: 0 auto;
  width: 1280px;
  padding-right: 10px;
  padding-left: 10px;
}

.about-main {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.page-heading {
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  font-size: 2.5rem;
  color: #fbeecf;
  font-weight: 800;
  border-bottom: 2px groove #ffd800;
  border-radius: 10px;
  padding-top: 10%;
  letter-spacing: 1.2px;
  width: 100%;
  margin: 0 auto;
  width: 26%;
  text-align: center;
  margin-bottom: 3%;
}

.page-subheading {
  font-size: 1.5rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #fbeecf;
  border-bottom: 3px groove;
  border-radius: 10px;
  border-color: #ffd800;
  text-align: center;
  margin: 0 0 10% 0;
  font-weight: 600;
  padding-bottom: 5px;
}

.about-content {
  margin-right: 10%;
}

.bio {
  font-family: "Comic Neue", cursive;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.5px;
  color: #fbeecf;
}

.signature {
  height: 25%;
  width: 25%;
}

.about-contact {
  display: flex;
  flex-direction: column;
}

li {
  text-decoration: none;
  list-style: none;
  padding: 1%;
  margin-bottom: 15%;
  color: #fbeecf;
}

ul {
  padding: 0;
  margin: 5% 0% 0% 0%;
}

.title {
  font-weight: 800;
  border-bottom: 2px solid #ffd800;
  margin-right: 2%;
  font-family: "Comic Neue", cursive;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-bottom: 2px;
}

.value {
  font-family: "Comic Neue", cursive;
  font-weight: 400;
  font-size: 1.2rem;
}

.download-resume {
  font-family: "Comic Neue", cursive;
  text-decoration: none;
  color: #131211;
  text-transform: uppercase;
  font-size: 1.3rem;
  padding: 4%;
  background-color: #fbeecf;
  border: none;
  border-radius: 6px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
}

.download-resume:hover {
  background-color: #ffd800;
  color: #131211;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    margin: 0 auto;
    width: 370px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .about-main {
    display: flex;
    flex-direction: column;
  }

  .page-heading {
    font-size: 1.4rem;
    padding-top: 20%;
    width: 100%;
    margin: 0 auto;
    width: 50%;
    text-align: center;
    margin-bottom: 9%;
  }

  .page-subheading {
    font-size: 1.2rem;
    margin: 4% auto 3% auto;

    width: 80%;
  }

  .about-content {
    margin: 0 auto;
  }

  .bio {
    font-size: 0.9rem;
    line-height: 2;
    letter-spacing: 0.5px;
  }

  li {
    margin-bottom: 2%;
    font-size: 0.9rem;
  }

  .value {
    font-size: 1rem;
  }

  .download-resume {
    font-size: 1rem;
    // padding: 4%;
    width: 55%;
    margin: 0% auto 12% auto;
  }
}
