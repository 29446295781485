.form-title {
  font-size: 1.5rem;
  font-family: "Comic Neue", cursive;
  text-transform: uppercase;
  color: #f3d942;
  border-bottom: 3px groove;
  border-radius: 10px;
  border-color: goldenrod;
  text-align: center;
  margin: 5% auto;
  width: 50%;
}

.input-section {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
}

.thankyou-section{
background-image: radial-gradient(circle, #103f3b, #0c3b35, #083630, #05322a, #032e25, #032c22, #022920, #02271d, #03251c, #03241b, #04221a, #042119);
  border-radius: 8px;
  min-height: 40vh;
  display: grid;
  grid-template-columns: 1fr 0fr 1fr;
  align-items: center;
}

.message-container{
  display: flex;
  flex-direction: column;

}

.contact-form {
  display: flex;
  flex-direction: column;
}

.name-email {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input-section .name-email input,
.message {
  background-color: #fbeecf;
  border-radius: 4px;
  padding: 1.5%;
  margin: 0 2%;
  font-size: 1.2rem;
  font-family: "Comic Neue", cursive;
  color: #000000;
}

.input-section .message {
  margin: 2% 1%;
  height: 12rem;
  resize: none;
}

.input-section .form-button {
  margin: 0 auto;
  font-family: "Comic Neue", cursive;
  text-decoration: none;
  color: #131211;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 1%;
  background-color: #fbeecf;
  border: none;
  border-radius: 6px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
}


.input-section .form-button:hover {
  background-color: #ffd800;
  color: #131211;
}

.section-line{
  height: 250px;
  width: 2px;
  background-color: #ffd800;
}

.far{
  font-size: 6rem;
  color: #fcfcfc;
  text-align: center;
  padding: 5% 0;
}

.submitted{
  font-size: 1.5rem;
  font-weight: 900;
}

.number{
  font-size: 4rem;
}

.lucky-number{
   font-family: 'Reenie Beanie',
   cursive;
   font-size: 2.5rem;
}

.cheers{
   margin: 1% auto 0 auto;
   font-family: 'Reenie Beanie',
   cursive;
    text-decoration: none;
    font-size: 2.5rem;
    padding: 1%;
    border: none;
    border-radius: 6px;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}
.submitted,
.thank-you{
    font-family: "Comic Neue",
    cursive;
     color: #ffffff;
    margin: 2% auto;
}

// ****** FOOTER SECTION STYLE ***** //

.contact-footer {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 4% 1% 3% 1%;
}

.contact-social {
  display: flex;
  flex-direction: column;
}

.contact-footer .contact-social .fas {
  font-size: 3rem;
  color: #ffd800;
}

.contact-type {
  font-family: "Comic Neue", cursive;
  color: #fbeecf;
  // text-transform: uppercase;
  font-size: 1.3rem;
  margin: 3% 1%;
  width: 100%;
}

// ****** Media queries  



@media only screen and (max-width: 600px) {

  .name-email {
    display: flex;
    flex-direction: column;
  }

  .input-section .name-email input,
  .message {
    margin: 2% auto;
    width: 100%;
    font-size: 1rem;
  }

  .input-section .form-button {
    font-size: 1rem;
    padding: 3%;
    margin: 3% auto;
  }

  .thankyou-section{
    display:flex;
    flex-direction: column;
  }

  .section-line{
    height: 1px;
    width: 90%;
  }


  .far{
    font-size: 4rem;
    padding-top: 10%;

  }
  .submitted{
    padding-top: 7%;
    font-size: 1.2rem;
  }
.thank-you{
  font-size: 1rem;
}

.number{
  font-size: 2.5rem;
}
  .cheers{
    font-size: 1.8rem;
    padding-top: 10%;
  }
  .contact-footer {
    flex-direction: column;
    padding-bottom: 10%;
  }

  .contact-type {
    font-size: 1.1rem;
  }

  .contact-footer .contact-social .fas {
    font-size: 1.3rem;
  }

}