 .message-wrapper {
   margin: 0 auto;
   width: 90%;
 }

 .messages-container {
   width: 100%;
   background-image: radial-gradient(circle, #103f3b, #0c3b35, #083630, #05322a, #032e25, #032c22, #022920, #02271d, #03251c, #03241b, #04221a, #042119);
   min-height: 60vh;
   overflow-y: scroll;
 }

 .message-table {
   width: 100%;
   color: #ffe608;

 }

 th,
 td {
   border: 1px solid #ffe608;
   text-align: center;
 }

 .table-heading .date {
   max-width: 10%;
 }

 .table-heading .name {
   max-width: 20%;

 }

 .table-heading .email {
   max-width: 10%;
 }

 .table-heading .message {
   max-width: 40%;
 }

 .table-heading .actions {
   max-width: 10%;
 }

 .client-message-header,
 .client-message {
   text-align: left;
   word-wrap: break-word;
   max-width: 15rem;
   font-size: 0.9rem;
   padding: 3px;
 }

 .hvr-grow {
   display: inline-block;
   vertical-align: middle;
   transform: translateZ(0);
   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
   backface-visibility: hidden;
   -moz-osx-font-smoothing: grayscale;
   transition-duration: 0.3s;
   transition-property: transform;
 }

 .hvr-grow:hover,
 .hvr-grow:focus,
 .hvr-grow:active {
   transform: scale(1.1);
   background-color: #fff;
 }

 .action-button {
   background-color: #0007058c;
   box-shadow: none;
   color: #08ffc1;
   border: none;
   padding: 1%;
   margin: 1% 3%;
   font-size: 1.2rem;
   width: 35px;
 }

 .table-data {
   color: #b5f7e6;
 }

 .delete {
   color: rgb(253, 36, 36);
   width: fit-content;
   padding: 5%;
   border-radius: 5px;
 }